import { ILetnaPriprava, ISprotnaPriprava } from './../model/priprava';
import axios, { AxiosPromise } from "axios";
import fileDownload from 'js-file-download';
import store from '@/store/store';

const baseAddress = process.env.VUE_APP_BASE_API_URL;

export function PostLetnaPripravaExport(priprava: ILetnaPriprava): Promise<void> {
    return axios.post(`${baseAddress}/priprave/letna/export`, JSON.stringify(priprava), { responseType: 'blob', headers: { authorization: 'Bearer ' + store.state.auth.token } }).then(res => {
        fileDownload(res.data, "letna-priprava.docx");
    });
}

export function PostLetnaPripravaStore(priprava: ILetnaPriprava): AxiosPromise<{success: boolean, id: number}> {
    return axios.post(`${baseAddress}/priprave/letna/store`, JSON.stringify(priprava), { headers: { authorization: 'Bearer ' + store.state.auth.token } });
}

export function PostSprotnaPripravaExport(priprava: ISprotnaPriprava): Promise<void> {
    return axios.post(`${baseAddress}/priprave/sprotna/export`, JSON.stringify(priprava), { responseType: 'blob', headers: { authorization: 'Bearer ' + store.state.auth.token } }).then(res => {
        fileDownload(res.data, "sprotna-priprava.docx");        
    });
}

export function PostSprotnaPripravaStore(priprava: ISprotnaPriprava): AxiosPromise<{success: boolean, id: number}> {
    return axios.post(`${baseAddress}/priprave/sprotna/store`, JSON.stringify(priprava), { headers: { authorization: 'Bearer ' + store.state.auth.token } });
}


export function GetLetnaPripravaList(): AxiosPromise<ILetnaPriprava[]> {
    return axios.get(`${baseAddress}/priprave/letna/list`,  { headers: { authorization: 'Bearer ' + store.state.auth.token } });
}

export function GetSprotnaPripravaList(): AxiosPromise<ISprotnaPriprava[]> {
    return axios.get(`${baseAddress}/priprave/sprotna/list`,  { headers: { authorization: 'Bearer ' + store.state.auth.token } });
}

export function GetLetnaPriprava(id: any): AxiosPromise<ILetnaPriprava> {
    return axios.get(`${baseAddress}/priprave/letna/${id}`,  { headers: { authorization: 'Bearer ' + store.state.auth.token } });
}

export function GetSprotnaPriprava(id: any): AxiosPromise<ISprotnaPriprava> {
    return axios.get(`${baseAddress}/priprave/sprotna/${id}`,  { headers: { authorization: 'Bearer ' + store.state.auth.token } });
}

