



























































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { GetPredmet, GetPredmeti } from '@/api';
import UcnaUra from '@/components/priprava/ucna-ura.vue';
import TemaList from '@/components/tema-list.vue';
import { IReference, ISprotnaPriprava, sprotnaPripravaCreate, uraCreate } from '@/model/priprava';
import { IPredmet, ITema } from '@/model';
import TextEditor from "@/components/priprava/priprava-text-editor.vue";
import { GetSprotnaPriprava, PostSprotnaPripravaExport, PostSprotnaPripravaStore } from '@/api/priprava';


@Component({ components: { UcnaUra, TemaList, TextEditor } })
export default class SprotnaPriprava extends Vue {
    @Prop()
    id!: number | null;

    @Prop()
    value!: ISprotnaPriprava;

    @Prop()
    stPrikazaneUre!: number | null;

    loading: boolean = false;
    searchTerm: string = "";

    searchSelectedProgram: string = "";
    searchSelectedPredmet: string = "";
    searchResults: any[] = [];
    tab: string = "filters";
    baseAddress = process.env.VUE_APP_BASE_API_URL;
    // indexPrikazaneUre: number = 1;

    stepForm: object = {
        step: 1,
        done1: false,
        done2: false,
        done3: false
    };

    async mounted() {
        console.log(this.id);


        //this.$store.commit("priprava/setSprotna", this.value ?? sprotnaPripravaCreate());
        this.loading = true;
        this.getData();
        GetPredmeti().then(d => this.$store.commit("setInitialData", d.data.predmeti)).finally(() => this.loading = false);
    }

    get isLoggedIn() {
        return (this.$store as any).state.auth.token != null;
    }

    get auth() {
        return (this.$store as any).state.auth;
    }

    @Watch('isLoggedIn')
    loggedInChanged() {

        this.getData();
    }


    async getData() {
        if (this.id && this.isLoggedIn) {
            const response = await GetSprotnaPriprava(this.id);
            this.priprava = response.data;
            this.priprava.id = this.id;
            this.$store.commit("priprava/setSprotna", this.priprava ?? sprotnaPripravaCreate());
            this.$store.commit('setSelectedProgram', this.priprava.program);
            const predmet: IPredmet = this.predmeti.find((p: IPredmet) => p.title.toLowerCase() == this.priprava.predmet.toLowerCase());
            console.log(this.predmeti);
            console.log(this.priprava.predmet);
            this.$store.commit('setSelectedPredmet', predmet);
            if (predmet?.title && predmet?.title == this.priprava.ref?.predmet && this.priprava.ref?.temaId) {
                const tema = predmet?.teme?.find((t: ITema) => t.id == this.priprava.ref?.temaId);
                this.$store.commit('setSelectedTema', tema);
            }
        } else if (this.isLoggedIn) {
            this.$store.commit("priprava/setSprotna", sprotnaPripravaCreate());
        }
        if (!this.priprava.ucitelj && (this.$store as any).state.auth?.user?.ime)
            this.priprava.ucitelj = (this.$store as any).state.auth?.user?.ime;
    }

    get priprava() {
        return this.$store.state.priprava.sprotna;
    }
    set priprava(value: ISprotnaPriprava) {
        this.$store.commit("priprava/setSprotna", value ?? sprotnaPripravaCreate());
    }

    @Watch("priprava.stUr")
    stUrChanged() {
        if (this.priprava.nacrtDejavnosti.length < this.priprava.stUr)
            for (let i = this.priprava.nacrtDejavnosti.length; i < this.priprava.stUr; i++)
                this.priprava.nacrtDejavnosti.push(uraCreate());
    }

    get indexPrikazaneUre() {
        return this.$store.state.priprava.stPrikazaneUre;
    }
    set indexPrikazaneUre(val: number) {
        this.$store.commit("priprava/setUra", val);
    }
    get izbranaUra() {
        let ura = this.priprava.nacrtDejavnosti[this.indexPrikazaneUre - 1];
        if (!ura) {
            ura = uraCreate();
            this.priprava.nacrtDejavnosti.splice(this.indexPrikazaneUre - 1, 0, ura);
        }
        return ura;
    }

    get programi() {
        const pr = [...new Set(this.$store.state.predmeti.map((p: IPredmet) => p.program))] as string[];
        pr.sort((a: string, b: string) => a < b ? -1 : (a > b ? 2 : 0));
        return pr;
    }

    get predmeti() {
        return this.predmetList(this.$store.state.selectedProgram);
    }



    predmetList(program: string) {
        if (program == null) return [];
        const predmetiPrograma = this.$store.state.predmeti.filter((p: IPredmet) => p.program.toLowerCase() == program.toLowerCase());
        predmetiPrograma.sort((a: IPredmet, b: IPredmet) => a.title < b.title ? -1 : (a.title > b.title ? 2 : 0));
        return predmetiPrograma;
    }

    sklopSelect(data: IReference) {
        this.priprava.sklop = data?.text ?? "";
        this.priprava.ref = data;
    }


    deactivatePicker() {
        this.$store.commit("priprava/setPicker", null);
    }

    @Watch("stepForm.step")
    stepperChanged() {
        this.deactivatePicker();
    }

    async setPredmet(predmetNziv: string) {
        let predmet = this.predmeti.find((p: IPredmet) => p.title.toLowerCase() == predmetNziv.toLowerCase());
        if (!predmet.isLoaded) {
            this.loading = true;
            try {
                const returned = await GetPredmet(predmet.id);

                predmet = { ...JSON.parse(returned.data.data), id: returned.data.id, isLoaded: true, program: predmet.program };

                this.$store.commit("addPredmet", predmet);
            } finally {
                this.loading = false;
            }
        }

        this.$store.commit("setSelectedPredmet", predmet);
        this.$store.commit("setSelectedTema", null);
        this.$store.commit("setSelectedSklop", null);
        this.$store.commit("setSelectedPodsklop", null);
        //window.scrollTo(0, 0);
        return predmet;
    }
    setProgram(program: string) {
        this.$store.commit('setSelectedProgram', program);
        if (this.$store.state.selectedPredmet && this.$store.state.selectedPredmet.program != program)
            this.$store.commit("setSelectedPredmet", null);
    }

    async exportClick() {
        await PostSprotnaPripravaExport(this.priprava);
        this.$q.notify({ message: 'Sprotna so bili uspešno izvoženi', type: 'positive', position: 'top' });
    }

    async storeClick() {
        const response = await PostSprotnaPripravaStore(this.priprava);
        if (response.data.success) {
            this.priprava.id = response.data.id;
            this.$q.notify({ message: 'Sprotna priprava je bila uspešno shranjena', type: 'positive', position: 'top' });
        }

    }
}
