
export default {
    namespaced: true,
    state: {

        leftDrawer: true,
        rightDrawer: true
    },
    getters: {

    },
    mutations: {
        toggleLeftDrawer(state: any) {
            state.leftDrawer = !state.leftDrawer;
        },
        toggleRightDrawer(state: any) {
            state.rightDrawer = !state.rightDrawer;
        }
    },
    actions: {

    },
};
