import Vue from 'vue';


import '@quasar/extras/roboto-font-latin-ext/roboto-font-latin-ext.css';
import '@quasar/extras/mdi-v5/mdi-v5.css';
import '@quasar/extras/material-icons/material-icons.css';
import {
    QCheckbox,
    Quasar, QLayout, QHeader,
    QDrawer,
    QPageContainer,
    QPage,
    QToolbar,
    QToolbarTitle,
    QBtn,
    QBtnToggle,
    QIcon,
    QList,
    QItem,
    QItemSection,
    QItemLabel,
    QCard,
    QCardSection,
    QInput,
    QTable,
    QTr,
    QTh,
    QTd,
    QStepper,
    QStep,
    QStepperNavigation,
    QBadge,
    QChip,
    QDialog,
    QSelect,
    QSpace,
    QAvatar,
    QToggle,
    QCircularProgress,
    QSeparator,
    QBanner,
    QExpansionItem,
    QTime,
    QDate,
    QPopupProxy,
    QImg,
    QMenu,
    QTooltip,
    QLinearProgress,
    QSpinner,
    QTab,
    QTabs,
    QTabPanel,
    QTabPanels,
    QRouteTab,
    QRange,
    QBreadcrumbs,
    QBreadcrumbsEl,
    ClosePopup,
    Ripple,
    Notify,
    Dialog,
    QPopupEdit,
    QEditor,
    QSlideTransition,
    QOptionGroup,
    QUploader,
    QCardActions,
    QScrollArea,
    QFile,
    QInnerLoading,
    QSplitter,
    TouchPan,
    QField,
    QPagination,
    QTimeline,
    QTimelineEntry
} from 'quasar';

import langSl from "quasar/lang/sl";

Vue.use(Quasar, {
    config: {
    },
    lang: langSl,
    components: {
        QCheckbox,
        QTab,
        QTabs,
        QTabPanel,
        QTabPanels,
        QRouteTab,
        QLayout,
        QHeader,
        QDrawer,
        QPageContainer,
        QPage,
        QToolbar,
        QToolbarTitle,
        QBtn,
        QBtnToggle,
        QIcon,
        QList,
        QItem,
        QItemSection,
        QItemLabel,
        QCard,
        QCardSection,
        QSeparator,
        QInput,
        QTable,
        QTr,
        QTh,
        QTd,
        QStepper,
        QStep,
        QStepperNavigation,
        QBadge,
        QChip,
        QDialog,
        QSelect,
        QSpace,
        QAvatar,
        QToggle,
        QCircularProgress,
        QSlideTransition,
        QBanner,
        QExpansionItem,
        QTime,
        QDate,
        QPopupProxy,
        QImg,
        QMenu,
        QBreadcrumbs,
        QBreadcrumbsEl,
        QTooltip,
        QLinearProgress,
        QSpinner,
        QRange,
        QPopupEdit,
        QEditor,
        QOptionGroup,
        QUploader,
        QCardActions,
        QScrollArea,
        QFile,
        QInnerLoading,
        QSplitter,
        QField,
        QPagination,
        QTimeline,
        QTimelineEntry
    },
    directives: {
        ClosePopup,
        Ripple,
        TouchPan
    },
    plugins: {
        Notify,
        Dialog
    }
});
