












































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IVsebina, ISklop, ISekcija } from '../model';
import Podsklop from "./podsklop.vue";
import Vsebina from "./vsebina.vue";

type IVsebinaWithVio = IVsebina & { vio: number[] };
@Component({ components: { Podsklop, Vsebina } })
export default class Gradivo extends Vue {
    @Prop()
    sklop !: any;
    @Prop()
    gradivoVisible !: boolean;

    editingGradivo: any = null;

    get dialogGradivo() {
        return this.editingGradivo != null;
    }
    set dialogGradivo(visible) {
        if (visible == false)
            this.editingGradivo = null;
    }

    addGradivo() {
        this.editingGradivo = { title: '', url: '', description: '' };
        //  this.$store.commit("addVsebina", { sklopId: this.$store.state.selectedSklop.id, id: this.vsebine.length * -1, text: 'aaa', razredi: [] });
    }
    addGradivoToSklop() {
        this.$store.state.selectedPredmet.pendingChanges = true;
        const tema = this.$store.state.selectedTema;
        const p = this.$store.state.selectedPredmet;
        this.$store.commit("setSelectedTema", tema);
        this.$store.commit("addPredmet", p);


        // debugger;
        if (this.editingGradivo.id) return;

        if (this.sklop.gradiva == null) Vue.set(this.sklop, "gradiva", [this.editingGradivo]);
        else this.sklop.gradiva.push(this.editingGradivo);
        this.editingGradivo.id = this.sklop.id + "_gradivo_" + this.sklop.gradiva.length;

    }
    removeGradivoFromSklop() {
        if (!this.sklop.gradiva) return;
        const index = this.sklop.gradiva.findIndex((g: { id: any }) => g.id == this.editingGradivo.id);
        this.sklop.gradiva.splice(index, 1);
        this.$store.commit("setPendingChanges", true);
        //this.$store.state.selectedPredmet.pendingChanges = true;
    }
}
