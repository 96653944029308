





































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Sklop from '@/components/sklop.vue';
import Podsklop from '@/components/podsklop.vue';
import Gradivo from '@/components/gradivo.vue';
import Vsebina from '@/components/vsebina.vue';
import Obvestilo from '@/components/obvestilo.vue';
import { GetPredmeti, PostPredmet, GetPredmet, DeletePredmet, RenamePredmet, Search } from '@/api';
import { IPredmet, ISklop, ITema, ISekcija } from '@/model';

@Component({ components: { Sklop, Podsklop, Gradivo, Vsebina, Obvestilo } })
export default class Nacrt extends Vue {
    gradivoVisible: boolean = false;
    loading = false;

    get tema(): ITema {
        return this.$store.state.selectedTema;
    }
    get sklop(): ISklop {
        return this.$store.state.selectedSklop;
    }
    get podsklop(): ISklop {
        return this.$store.state.selectedPodsklop;
    }
    get predmet(): IPredmet {
        return this.$store.state.selectedPredmet;
    }
    get sekcijeTeme() {
        if (this.tema) {
            const filters = this.$store.state.filters;
            return this.tema.sekcije.filter((s: ISekcija) => filters[s.title] != false);
        }
        return null;
    }
    get sekcijeSklopa() {
        if (this.sklop) {
            const filters = this.$store.state.filters;
            return this.sklop.sekcije.filter((s: ISekcija) => filters[s.title] != false);
        }
        return null;
    }
    get sekcijePredmeta() {
        if (this.predmet) {
            const filters = this.$store.state.filters;
            return this.predmet.sekcije.filter((s: ISekcija) => filters[s.title] != false);
        }
        return [];
    }
    get sklopi() {
        return this.tema.sklopi;
    }

    @Watch("predmet")
    async predmetChanged(predmet: any) {
        if (!predmet?.isLoaded && predmet?.id) {

            this.loading = true;
            try {
                const returned = await GetPredmet(predmet.id);

                predmet = { ...JSON.parse(returned.data.data), id: returned.data.id, isLoaded: true, program: predmet.program };

                this.$store.commit("addPredmet", predmet);
            } finally {
                this.loading = false;
            }

             this.$store.commit("setSelectedPredmet", predmet);
            this.$store.commit("setSelectedTema", null);
            this.$store.commit("setSelectedSklop", null);
            this.$store.commit("setSelectedPodsklop", null);
            window.scrollTo(0, 0);
        }

       
        return predmet;
    }
}
