

















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { PostAuthLogin } from '@/api/auth';

@Component({ components: {} })
export default class Login extends Vue {
    username: string = "";
    password: string = "";
    type: "sris" | "ss2021" | "" = "sris";
    register: boolean = false;


    failed: boolean = false;
    loading: boolean = false;

    get isLoggedIn() {
        return (this.$store as any).state.auth.token != null;
    }

    async doLogin() {
        try {
            this.loading = true;
            const response = await PostAuthLogin(this.username, this.password, this.register ? this.type : "");

            const data = { token: response.data.token, user: response.data.user, expiration: response.data.expiration };
            this.$store.commit("auth/setData", data);

            sessionStorage.setItem("usr-data", JSON.stringify(data));
            this.failed = false;

        } catch {
            this.failed = true;
            this.password = "";
        }
        finally { this.loading = false; }
    }

    @Watch("password")
    gesloChanged() {
        if (this.failed && this.password != "")
            this.failed = false;
    }
    //commit usr-data to store
    srisLogin(){
        this.register = true;
        this.type = 'sris';
    }
    backToLogin(){
        this.register = false;
        this.type = '';
    }

}
