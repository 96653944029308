
































































































import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
import { IDejavnost, IUcnaUra } from '@/model/priprava';
import bloom from '@/model/taxonomy/tax-bloom';
import { debounce } from "@/utils/debounce";
import TextEditor from "./priprava-text-editor.vue";
import { ITaxnomoyCategory } from '@/model/taxonomy/taxonomy';

@Component({ components: { TextEditor } })
export default class Dejavnost extends Vue {
    @Prop()
    value!: any;
    @Prop()
    config!: any;
    @Prop()
    ura!: IUcnaUra;

    debouncedGetTaxonomiy = debounce(this.taxonomy1, 500);

    dejavnost: IDejavnost = {
        cas: "0",
        taxonomy: { color: 'grey', labelClass: "bg-grey-4 text-grey-1", naziv: "", glagol: "" },
        ucneOblike: [],
        dejavnostiUcenca: "",
        dejavnostiUcitelja: "",
    };

    mounted() {
        this.dejavnost = this.value;
    }

    @Watch("value")
    valueChanged() {
        this.dejavnost = this.value;
    }

    get maxStMinut() {
        const skupniCas = this.ura.dejavnosti.reduce((agg, curr) => {
            if (curr == this.dejavnost || !curr) return agg;
            agg += Number(curr.cas);
            return agg;
        }, 0);
        return 45 - skupniCas;
    }


    taxonomy1() {
        /*const tax = bloom.infer(this.dejavnost.dejavnostiUcenca.replace(/(<([^>]+)>)/gi, "").split(" "));
        if (tax && tax.length > 0) {
            console.log("Tax->", tax[0]);
            this.dejavnost.taxonomy = tax[0];
            return tax[0];
        }*/

        return { color: 'grey', labelClass: "bg-grey-4 text-grey-1", naziv: "" };
    }

    dejavnostUcencaChanged() {
        this.debouncedGetTaxonomiy();
    }

    @Emit("removeDejavnost")
    removeDejavnost() {
        return this.$vnode.key;
    }

   
}
