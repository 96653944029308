import { ISprotnaPriprava, ILetnaPriprava } from '@/model/priprava';
import { IPredmet } from '../model';
import { StolpciDejavnosti, StolpciSprotnePriprave, StolpciLetnegaNacrtovanja, StolpciLetnegaPreverjanja } from '@/model/priprava-dodatni-stolpci';

interface IPripravaState {
    sprotna: ISprotnaPriprava | null;
    selectedProgram: string | null;
    selectedPredmet: IPredmet | null;
    letna: ILetnaPriprava | null;
    pickerFunction: (el: any) => void | null;
    dodatniStolpci: {
        dejavnosti: Array<{ label: string, value: string }>,
        sprotna: Array<{ label: string, value: string }>,
        letna: Array<{ label: string, value: string }>,
        letnaPreverjanja: Array<{ label: string, value: string }>,
    };
    stPrikazaneUre: number;
}

export default {
    namespaced: true,
    state: {
        sprotna: null,
        letna: null,
        selectedProgram: null,
        selectedPredmet: null,
        pickerFunction: null,
        dodatniStolpci: {
            dejavnosti: StolpciDejavnosti,
            sprotna: StolpciSprotnePriprave,
            letna: StolpciLetnegaNacrtovanja,
            letnaPreverjanja: StolpciLetnegaPreverjanja
        },
        stPrikazaneUre: 1
    },
    getters: {
        dodatniStolpciDejavnosti(state: IPripravaState) {
            return state.dodatniStolpci.dejavnosti.reduce((agg, curr) => { agg[curr.value] = curr; return agg; }, {} as Record<string, any>);
        },
        dodatniStolpciSprotna(state: IPripravaState) {
            return state.dodatniStolpci.sprotna.reduce((agg, curr) => { agg[curr.value] = curr; return agg; }, {} as Record<string, any>);
        },
        dodatniStolpciLetna(state: IPripravaState) {
            return state.dodatniStolpci.letna.reduce((agg, curr) => { agg[curr.value] = curr; return agg; }, {} as Record<string, any>);
        },
        dodatniStolpciPreverjanje(state: IPripravaState) {
            return state.dodatniStolpci.letnaPreverjanja.reduce((agg, curr) => { agg[curr.value] = curr; return agg; }, {} as Record<string, any>);
        }
    },
    mutations: {
        setSelectedPredmet(state: IPripravaState, p: IPredmet) {
            state.selectedPredmet = p;
        },
        setSelectedProgram(state: IPripravaState, program: string) {
            state.selectedProgram = program;
        },
        setSprotna(state: IPripravaState, priprava: ISprotnaPriprava) {
            state.sprotna = priprava;
        },
        setLetna(state: IPripravaState, priprava: ILetnaPriprava) {
            state.letna = priprava;
        },
        setPicker(state: any, func: (el: any) => void | null) {
            state.pickerFunction = func;
        },
        setUra(state: IPripravaState, st: number) {
            state.stPrikazaneUre = st;
        }
    },
    actions: {

    },
};
