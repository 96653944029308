import { Node, mergeAttributes } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-2';
import KatexInlineComponent from "@/components/editor/katex-inline.vue";

export interface KatexOptions {
    HTMLAttributes: Record<string, any>;

}

declare module '@tiptap/core' {
    interface Commands {
        katex: {

        };
    }
}

export const Katex = Node.create<KatexOptions>({

    name: 'katex',
    inline: true,
    group: 'inline',
    selectable: true,

    defaultOptions: {
        HTMLAttributes: {},
    },

    addAttributes() {
        return {
            src: {
                default: "\\latex",
            },
            mathml: {
                default: ""
            }
        };
    },

    parseHTML() {
        return [
            {
                tag: 'ki', getAttrs: (dom: any) => {

                    const o = { src: decodeURIComponent(dom.getAttribute('src')), mathml: dom.getAttribute('mathml') };
                    return o;
                }
            },
            {
                tag: 'span',
                getAttrs: (node: any) => {

                    if (node.className != "katex") return false;
                    const mathml = node.querySelector("math[xmlns=\"http://www.w3.org/1998/Math/MathML\"]")?.outerHTML;

                    const tex = node.querySelector("annotation[encoding=\"application/x-tex\"]")?.textContent;
                    if (tex) {
                        return { src: tex, mathml };
                    }
                    return false;
                }

            }
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['ki', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
    },

    addCommands() {
        return {

        };
    },
    addNodeView() {
        return VueNodeViewRenderer(KatexInlineComponent as any);
    },

});
