
import axios, { AxiosPromise } from "axios";
import store from '@/store/store';
import zavodi from "@/data/zavodi";

const baseAddress = process.env.VUE_APP_BASE_API_URL;

export function PostAuthLogin(user: string, password: string,  type: string): AxiosPromise<{token: string, user: any, expiration: number}> {
    return axios.post(`${baseAddress}/auth/login`, {username: user, password, type}, {   });
}

export function PostGetUser(user: string): AxiosPromise<any> {
    return axios.post(`${baseAddress}/auth/get`, {username: user}, { headers: { authorization: 'Bearer ' + store.state.auth.token }  });
}

export function PostCreateUser(user: string, password: string, ime: string, zavod: string): AxiosPromise<any> {
    return axios.post(`${baseAddress}/auth/create`, {username: user, zavod, password, ime }, { headers: { authorization: 'Bearer ' + store.state.auth.token }  });
}