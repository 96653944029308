import Vue from 'vue';
import Vuex from 'vuex';
import { IPredmet, ITema, ISklop } from '../model';

//modules
import priprava from './priprava';
import ui from './ui';
import auth from './auth';
Vue.use(Vuex);


const predmet: IPredmet | null = null;
const tema: ITema | null = null;

export default new Vuex.Store({
    modules: { priprava, ui, auth },
    state: {
        termsAccepted: localStorage.getItem("termsAccepted") == "true",
        language: 'sl-si',
        token: '',

        editMode: false,

        selectedPredmet: predmet,
        selectedTema: tema,
        selectedSklop: null,
        selectedPodsklop: null,
        selectedProgram: null,
        selectedSearchResult: null,

        predmeti: [] as IPredmet[],
        programi: [],
        teme: [] as ITema[],
        allowEditing: false,

        filters: {
            razred: {
                min: 1,
                max: 9
            },
            vio: false,
            cilji: true,
            vsebine: true,
            standardi: true,

        }
    },
    getters: {

    },
    mutations: {
        setLanguage(state, lang) {
            state.language = lang;
        },
        setToken(state, token: string) {
            state.token = token;
        },
        setTermsAccepted(state) {
            state.termsAccepted = true;
            localStorage.setItem("termsAccepted", "true");
        },
        addPredmet(state, data) {
            const existingIndex = state.predmeti.findIndex(p => p.id == data.id);
            if (data.id != null && existingIndex > -1) {

                const newObj = { ...data };
                Vue.set(newObj, "isLoaded", true);
                state.predmeti.splice(existingIndex, 1, newObj);
                if (state.selectedPredmet != null && (state.selectedPredmet as any).id == data.id) {
                    state.selectedPredmet = newObj;
                }
            } else {
                Vue.set(data, "isLoaded", true);
                state.predmeti.push(data);
            }

        },
        deletePredmet(state, id) {
            const existingIndex = state.predmeti.findIndex(p => p.id == id);
            if (existingIndex > -1) {
                state.predmeti.splice(existingIndex, 1);
                if (state.selectedPredmet != null && (state.selectedPredmet as any).id == id) {
                    state.selectedPredmet = null;
                }
            }

        },
        setSelectedPredmet(state, p) {
            state.selectedPredmet = p;
        },
        setSelectedProgram(state, program) {
            state.selectedProgram = program;
        },
        setSelectedTema(state, t) {
            state.selectedTema = t;
        },
        setSelectedSklop(state, sklop) {
            state.selectedSklop = sklop;
        },
        setSelectedPodsklop(state, podsklop) {
            state.selectedPodsklop = podsklop;
        },
        setSelectedSearchResult(state, searchResult) {
            state.selectedSearchResult = searchResult;
        },
        setFilter(state, filter) {
            state.filters = { ...state.filters, ...filter };
        },
        setEditMode(state, enabled) {
            state.editMode = enabled == true;
        },
        setAllowEditing(state, enabled) {
            state.allowEditing = enabled;
        },
        setPendingChanges(state, isChanged) {
            if (state.selectedPredmet != null) {
                Vue.set(state.selectedPredmet as any, "pendingChanges", isChanged);
            }
        },
        setInitialData(state, data: IPredmet[]) {
            data.forEach(d => d.isLoaded = false);
            state.predmeti = data;
        },
    },
    actions: {

    },
}) as any;
