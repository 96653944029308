import { ITaxnomoyCategory } from './taxonomy/taxonomy';

export interface ILetnaPriprava {
    id?: number;
    predmet: string;
    program: string;
    razred: string;
    ucitelj: string;
    zavod: string;
    solskoLeto: string;
    sklopi: IVsebinskiSklop[];
    preverjanja: IPreverjanje[];
    config: { dodatniStolpci: string[], stolpciPreverjanja: string[]; };
}

export interface IPreverjanje {
    id: number;
    obdobje: {
        from: string | null;
        to: string | null;
    };
    casovnoObdobje: string;
    vrsta: string;
    nacini: string;
    standardi: string;
    merila: string;
}

export interface IReference {
    text: string | null;
    temaId: number | null;
    sklopId: number | null;
    podsklopId: number | null;
    programId: number | null;
    predmet?: string;
    program?: string;
    sklop?: string;
    podsklop?: string;
}

export interface IVsebinskiSklop {
    id: number;
    sklop: string;
    obdobje: {
        from: string | null;
        to: string | null;
    };
    stUr: number | null;
    vsebina: string;
    cilji: string;

    standardi: string;
    medpredmetnePovezave: string;
    opombe: string;
    ref: IReference;
}


export interface ISprotnaPriprava {
    tip: 'enopredmetni' | 'medpredmetni';
    id?: number;
    predmet: string;
    program: string;
    razred: string;
    ucitelj: string;
    obdobje: string;
    sklop: string;
    stUr: number;
    avtor: number;    
    zavod: string;
    solskoLeto: string;
    datum: string;
    cilji: string;
    standardi: string;
    nacrtDejavnosti: Array<IUcnaUra | null>;
    dodatniSklopi: IDodatniSklopi[];
    config: { stoplciPriprave: string[], stoplciDejavnosti: string[] };
    ref?: IReference;
}

export interface IDodatniSklopi {
    tip: string;
    data: string;
}

export interface IDodatniStolpci {
    tip: string;
    naziv: string;
}


export interface IUcnaUra {
    st: number;
    tipi: ETipUcneUre[];
    dejavnosti: IDejavnost[];
    metode: string[];
    ucneOblike: string[];
    nameniUcenja: string;
    dokazi: string;
    kriteriji: string;
    opombe: string;
}

export function uraCreate() {
    return {
        st: 0,
        tipi: [],
        metode: [],
        ucneOblike: [],
        dejavnosti: [dejavnostCreate()],
        nameniUcenja: "",
        dokazi: "",
        kriteriji: "",
        opombe: ""
    };
}


export function dejavnostCreate() {
    return {
        cas: "0",
        taxonomy: { color: 'grey', labelClass: "bg-grey-4 text-grey-1", naziv: "", glagol: "" },
        ucneOblike: [],
        dejavnostiUcenca: "",
        dejavnostiUcitelja: "",
    };
}

export function sprotnaPripravaCreate(): ISprotnaPriprava {
    return {
        predmet: "",
        program: "",
        razred: "",
        ucitelj: "",
        obdobje: "",
        stUr: 1,
        avtor: 0,
        sklop: "",
        zavod: "",
        solskoLeto: "",
        datum: "",
        config: {
            stoplciPriprave: ["viri"],
            stoplciDejavnosti: []
        },
        tip: 'enopredmetni',
        standardi: "",
        cilji: "",
        nacrtDejavnosti: [],
        dodatniSklopi: []
    };
}

export function letnaPripravSklopCreate(id: number): IVsebinskiSklop {
    return {
        id,
        sklop: "",
        obdobje: {
            from: null,
            to: null,
        },
        stUr: null,
        vsebina: "",
        cilji: "",
        standardi: "",
        medpredmetnePovezave: "",
        opombe: "",
        ref: { text: null, sklopId: null, temaId: null, podsklopId: null, programId: null  }
    };
}

export function letnaPripravPreverjanjeCreate(id: number): IPreverjanje {
    return {
        id,
        obdobje: {
            from: null,
            to: null,
        },
        casovnoObdobje: "",
        vrsta: "",
        nacini: "",
        standardi: "",
        merila: "",
    };
}

export function letnaPripravCreate(): ILetnaPriprava {
    return {
        program: "",
        predmet: "",
        sklopi: [letnaPripravSklopCreate(0)],
        ucitelj: "",
        zavod: "",
        razred: "",
        solskoLeto: "2021/2022",
        preverjanja: [letnaPripravPreverjanjeCreate(0)],
        config: { dodatniStolpci: [], stolpciPreverjanja: [] },
    };
}


export interface IDejavnost {
    cas?: string;
    taxonomy?: ITaxnomoyCategory | null;
    ucneOblike: string[];
    dejavnostiUcenca: string;
    dejavnostiUcitelja?: string;
    klasifikacija?: string;
    klasifikacijaAuto?: string;
}


export enum ETipUcneUre {
    'usvajanje',
    'utrevanje',
    'preverjanje',
    'ocenjevanje'
}


