<template>
    <node-view-wrapper class="vue-component" as="span">
        <span class="o-katex-inline">
            <span v-html="html"></span>
            <q-menu content-class="o-katex-editor" :offset="[0, 8]">
                <section>
                    <q-input v-model="src" @input="onEditorChanged" class="q-ma-xs q-ml-sm" />
                    <div class="row col-12 justify-between o-toolbar">
                        <div class="col-auto actions q-ml-sm">
                            Formula je v LaTex formatu, klikni gumb pomoč za primere
                            <q-btn icon="help_outline" @click="onHelp" flat />
                        </div>
                    </div>
                </section>
            </q-menu>
        </span>
    </node-view-wrapper>
</template>

<script>


//import 'src/css/katex/katex.min.css'
const katex = require('katex');
import { NodeViewWrapper } from '@tiptap/vue-2';
export default {
    name: 'o-katex-inline',
    data() {
        return {
            example: '',
            html: '',
            error: false,
            timeout: 0,
            // editor
        };
    },
    props: ['node', 'updateAttributes ', 'view'],
    components: {
        NodeViewWrapper
    },
    methods: {
        init() {
            if (!this.src) {
                setTimeout(() => {
                    //  this.src = 'E=mc^2'
                    this.renderKatex();
                }, 100);
            } else {
                this.renderKatex();
            }
        },
        renderKatex() {
            try {
                this.error = false;
               // debugger;
                const formula = (decodeURIComponent(this.src) || "formula").toString();

                this.html = katex.renderToString(formula);
            } catch (e) {
                this.error = true;
                this.html = e.message;
            }
        },
        onEditorChanged() {
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.renderKatex();
            }, 300);
        },
        onHelp() {
            window.open('https://sl.wikipedia.org/wiki/Wikipedija:TeXov_zapis#Posebni_znaki', '_blank');
        }
    },
    computed: {
        src: {
            get() {
                console.log(this.node);
                return decodeURIComponent(this.node.attrs.src);
            },
            set(src) {
                const mathMl = katex.renderToString(src, { output: 'mathml' });
                var encodedmml = encodeURIComponent(mathMl);
                this.updateAttributes({
                    src: encodeURIComponent(src),
                    mathml: encodedmml
                });
            }
        }
    },
    mounted() {
        this.init();
        console.log(this.node);
    }
};
</script>

<style lang="stylus">
.o-katex-inline {
    position: relative;
    display: inline;
    white-space: unset;
    cursor: pointer;
}

.o-katex-inline:hover {
    background: rgba(0, 0, 0, 0.05);
}

.o-katex-editor {
    min-width: 600px;

    .o-toolbar {
        background: rgba(0, 0, 0, 0.05);
    }

    .CodeMirror {
        height: 200px;
    }
}
</style>