















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IVsebina, ISklop, ISekcija } from '../model';
import Podsklop from "./podsklop.vue";
import Vsebina from "./vsebina.vue";
import Gradivo from "./gradivo.vue";
import trim from '@/utils/trim';

type IVsebinaWithVio = IVsebina & { vio: number[] };
@Component({ components: { Podsklop, Vsebina, Gradivo } })
export default class Sklop extends Vue {
    @Prop()
    sklop !: any;
    @Prop()
    tema !: any;
    gradivoVisible: boolean = false;

    get minRazred() {
        return this.$store.state.filters.razred.min;
    }
    get maxRazred() {
        return this.$store.state.filters.razred.max;
    }

    get sekcijeSklopa() {
        if (this.sklop) {
            const filters = this.$store.state.filters;
            return this.sklop.sekcije.filter((s: ISekcija) => filters[trim(s.title)] != false);
        }
        return null;
    }

    razrediToVio(razredi: number[]) {
        return [...new Set(razredi.map(r => Math.floor((r - 1) / 3 + 1)))];
    }
    sortByRazred(a: { razredi: number[] }, b: { razredi: number[] }) {
        const minA = a.razredi.reduce((acc: number, curr: number) => curr < acc ? curr : acc, 9);
        const minB = b.razredi.reduce((acc: number, curr: number) => curr < acc ? curr : acc, 9);
        return minA > minB ? 1 : minB > minA ? -1 : 0;
    }

    addVsebina() {
        //  this.$store.commit("addVsebina", { sklopId: this.$store.state.selectedSklop.id, id: this.vsebine.length * -1, text: 'aaa', razredi: [] });
    }


    pasteCapture(ref: any, evt: any) {
        ref = ref[0];
        let text;
        let onPasteStripFormattingIEPaste;
        evt.preventDefault();
        if (evt.originalEvent && evt.originalEvent.clipboardData.getData) {
            text = evt.originalEvent.clipboardData.getData('text/plain');
            ref.runCmd('insertText', text);
        } else if (evt.clipboardData && evt.clipboardData.getData) {
            text = evt.clipboardData.getData('text/plain');
            ref.runCmd('insertText', text);
        } else if ((window as any).clipboardData && (window as any).clipboardData.getData) {
            if (!onPasteStripFormattingIEPaste) {
                onPasteStripFormattingIEPaste = true;
                ref.runCmd('ms-pasteTextOnly', text);
            }
            onPasteStripFormattingIEPaste = false;
        }
    }
}
