























































import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator';
import { IDodatniStolpci, IUcnaUra, IDejavnost, dejavnostCreate, uraCreate } from '@/model/priprava';
import DejavnostiUcenca from './dejavnosti-ucenca.vue';

@Component({ components: { DejavnostiUcenca } })
export default class UcnaUra extends Vue {
    @Prop()
    value !: any;
    @Prop()
    stUre!: number;
    @Prop()
    config!: any;

    ucnaUra: IUcnaUra = uraCreate();

    metode: string[] = [
        'razlaga',
        'pogovor',
        'delo z besedilom',
        'delo z zemljevidom',
        'demonstracija',
        'raziskovanje',
        'praktično delo',
        'eksperimentiranje',
        'gibalne in druge dejavnosti',
        'pisna dela',
        'risanje',
        'igra',
    ];
    filteredMetode = this.metode;


    filterFn(val: string, update: (x: () => void) => void) {
        update(() => {
            if (val === '') {
                this.filteredMetode = this.metode;
            } else {
                const needle = val.toLowerCase()
                this.filteredMetode = this.metode.filter(
                    v => v.toLowerCase().indexOf(needle) > -1
                )
            }
        })
    }
    mounted() {
        this.ucnaUra = this.value; // { ...this.ucnaUra, ...this.value ?? {} };

        if (!this.ucnaUra.dejavnosti?.length) {
            this.ucnaUra.dejavnosti.push(dejavnostCreate());
        }
    }

    get taxonomyBar() {
        const res: Array<{ skupniCas: number, color?: string }> = [];
        let skupniCas = 0;
        this.ucnaUra.dejavnosti.forEach((dejavnost: IDejavnost) => {
            skupniCas += parseInt(dejavnost.cas?.toString() ?? "5", 10) / 45 * 100;
            res.push({ skupniCas, color: dejavnost.taxonomy?.color });
        });
        return res;
    }

    get taxString() {
        const res = [];
        let last: any = null;
        for (let i = 0; i < this.taxonomyBar.length; i++) {
            if (last == null)
                res.push(`${this.taxonomyBar[i].color} ${this.taxonomyBar[i].skupniCas}%`);
            else {
                res.push(`white ${last.skupniCas + 1}%`);
                res.push(`${this.taxonomyBar[i].color} ${last.skupniCas}%`);

                res.push(`${this.taxonomyBar[i].color} ${this.taxonomyBar[i].skupniCas}%`);
            }

            if (this.taxonomyBar[i].skupniCas < 100) {
                res.push(`#e0e0e0 ${this.taxonomyBar[i].skupniCas}%`);
                //res.push(`#ccc ${this.taxonomyBar[i].skupniCas}%`);
            }
            last = this.taxonomyBar[i];

        }
        return res.join(",");

        //red 10%, blue 10%, blue 50%, green 50%, green 100%
    }


    dodajDejavnost() {
        this.ucnaUra.dejavnosti.push(dejavnostCreate());
    }

    odstraniDejavnost(id: number) {
        this.ucnaUra.dejavnosti.splice(id, 1);
    }

    @Watch("value")
    function() {
        this.ucnaUra = this.value;
    }

    @Emit("input")
    ucnaUraChanged() {
        return this.ucnaUra;
    }

}
