export const StolpciLetnegaNacrtovanja: Array<{ label: string; value: string }> = [
    {
        label: "Opombe o realizaciji in refleksija",
        value: "Opombe o realizaciji in refleksija",
    },
    {
        label: "Medpredmetne povezave",
        value: "Medpredmetne povezave",
    },
];

export const StolpciLetnegaPreverjanja: Array<{ label: string; value: string }> = [
    {
        label: "Kriteriji uspešnosti",
        value: "Kriteriji uspešnosti",
    },
    {
        label: "Opombe",
        value: "Opombe",
    }
];

export const StolpciDejavnosti: Array<{ label: string; value: string }> = [
    {
        label: "Dejavnosti učitelja",
        value: "Dejavnosti učitelja",
    },
    {
        label: "Refleksija",
        value: "Refleksija",
    },
];

export const StolpciSprotnePriprave: Array<{ label: string; value: string }> = [
    {
        label: "Potrebno predznanje",
        value: "Potrebno predznanje",
    },
    {
        label: "Nameni učenja",
        value: "Nameni učenja",
    },
    {
        label: "Kriteriji uspešnosti",
        value: "Kriteriji uspešnosti",
    },
    {
        label: "Dokazi o učenju",
        value: "Dokazi o učenju",
    },
    {
        label: "Kompetence / prečne veščine",
        value: "Kompetence / prečne veščine",
    },
    {
        label: "Področje spremljanja",
        value: "Področje spremljanja",
    },
    {
        label: "Učna individualizacija in diferenciacija",
        value: "Učna individualizacija in diferenciacija",
    },
    {
        label: "Prilagoditve za učence s posebnimi potrebami",
        value: "Prilagoditve za učence s posebnimi potrebami",
    },
    {
        label: "Učna sredstva",
        value: "Učna sredstva",
        /*value:{imePolja: "", tile: "", control:"textarea"}*/
    },
    {
        label: "Varstvo pri delu",
        value: "Varstvo pri delu",
    },
    {
        label: "Viri in literatura",
        value: "Viri in literatura",
    },
];
