































import { Component, Vue, Emit } from 'vue-property-decorator';
import bloom from '@/model/taxonomy/tax-bloom';
import TextEditor from "./priprava-text-editor.vue";
import { IDejavnost, IUcnaUra } from '@/model/priprava';

@Component({ components: { TextEditor } })
export default class SprotnaPregledDejavnost extends Vue {

    get priprava() {
        return this.$store.state.priprava.sprotna;
    }

    @Emit("removeDejavnost")
    removeDejavnost() {
        return this.$vnode.key;
    }

    taxonomyBar(ucnaUra: IUcnaUra) {
        const res: Array<{ skupniCas: number, color?: string }> = [];
        let skupniCas = 0;
        ucnaUra.dejavnosti.forEach((dejavnost: IDejavnost) => {
            skupniCas += parseInt(dejavnost.cas?.toString() ?? "5", 10) / 45 * 100;
            res.push({ skupniCas, color: dejavnost.taxonomy?.color });
        });
        return res;
    }

    getProgressBar(ucnaUra: IUcnaUra) {
        const bar = this.taxonomyBar(ucnaUra);
        const res = [];
        let last: any = null;
        for (let i = 0; i < bar.length; i++) {
            if (last == null)
                res.push(`${bar[i].color} ${bar[i].skupniCas}%`);
            else {
                res.push(`white ${last.skupniCas + 1}%`);
                res.push(`${bar[i].color} ${last.skupniCas}%`);

                res.push(`${bar[i].color} ${bar[i].skupniCas}%`);
            }

            if (bar[i].skupniCas < 100) {
                res.push(`#e0e0e0 ${bar[i].skupniCas}%`);
                //res.push(`#ccc ${bar[i].skupniCas}%`);
            }
            last = bar[i];

        }
        console.log(res);
        return res.join(",");

        //red 10%, blue 10%, blue 50%, green 50%, green 100%
    }

}
