
import TiptapUnderline from '@tiptap/extension-underline';

export const Underline = TiptapUnderline.extend({
    parseHTML() {
        return [
          {
            tag: 'u',
          },
          {
            tag: 'b',
            getAttrs: node => (node as HTMLElement).style.fontWeight !== 'normal' && null,
          },
          /*{
            style: 'text-decoration',
            consuming: false,
            getAttrs: style => ((style as string).includes('underline') ? {} : false),
          },*/
        ];
      },
  });
