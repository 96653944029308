


















import { Component, Prop, Vue } from 'vue-property-decorator';
import TextEditor from "@/components/editor/text-editor.vue";
import { CommandProps, Editor } from '@tiptap/core';
import { TextSelection } from 'prosemirror-state';
import katex, { KatexOptions } from 'katex';
import he from 'he';

@Component({ components: { TextEditor } })
export default class PripravaTextEditor extends Vue {
    @Prop()
    value!: any;
    @Prop()
    label!: string;
    @Prop({ required: false })
    pretext!: string;
    @Prop({ default: false })

    allowPicking!: boolean;
    onFocus(ev: { editor: Editor }) {
        console.log(this.allowPicking);
        if (this.allowPicking)
            this.activatePicker(ev);
        this.$emit("focus", ev);
    }

    activatePicker(node: { editor: Editor }) {

        console.log("activating picker");
        this.$store.commit("priprava/setPicker", (el: any) => {
            let txt = el.text;
            const r = /\$\$(.*?)\$\$/g;
            if (el.containsFormulas) {
                txt = el.text.replace(r, (match: any, p1: string, offset: number, str: string) => {
                    p1 = he.decode(p1);
                    const mathMl = katex.renderToString(p1, { output: 'mathml' });
                    var encoded = encodeURIComponent(mathMl);
                    var encodedp1 = encodeURIComponent(p1);
                    console.log(encodedp1);
                    return `<ki src="${encodedp1}" mathml="${encoded}"></ki>`;

                });

            }
            if (el.type == '5' || el.type == '6' || el.type == '7' || el.type == '8')
                txt = "<li>" + txt + "</li>";
            else if (el.type == '0' || el.type == '1' || el.type == '2' || el.type == '3' || el.type == '4')
                txt = "<p>" + txt + "</p>";
            if (node.editor.getHTML() == "<p></p>") node.editor.commands.setContent(txt);
            else node.editor.chain().focus().command((n: CommandProps) => {
                // manipulate the transaction
                //n.tr.selection.
                n.tr.setSelection(TextSelection.atEnd(n.tr.doc));
                return true;
            }).insertContent(txt).run();
            this.$emit("input", node.editor.getHTML());

        });
    }
}
