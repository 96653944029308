import TiptapBold from '@tiptap/extension-bold';

export const Bold = TiptapBold.extend({
    parseHTML() {
        return [
            {
                tag: 'strong',
            },
            {
                tag: 'b',
                getAttrs: node => (node as HTMLElement).style.fontWeight !== 'normal' && null,
            },
            /*{
              style: 'font-weight',
              getAttrs: value => {
                  if(va)
                  return /^(bold(er)?|[5-9]\d{2,})$/.test(value as string) && null;
              }
            },*/
        ];
    },
});
