
import axios, { AxiosResponse, AxiosPromise } from 'axios';
import { IPredmet } from '@/model';
import store from '@/store/store';

const baseAddress = process.env.VUE_APP_BASE_API_URL;

let cachedGetPredmetiPromise: AxiosPromise<{ predmeti: IPredmet[] }> | null = null;
let getPredmetInProgress: AxiosPromise<any> | null = null;

export function PostGetToken(username: string, password: string): AxiosPromise<string> {
    const bodyFormData = new FormData();

    bodyFormData.append('username', username);
    bodyFormData.append('password', password);

    return axios.post(`${baseAddress}/auth`, { username, password });
}
/*

export function PostPrijavnica(prijavnica: any): AxiosPromise<IEnotaZrss[]> {
    return axios.post(`${baseAddress}/prijavnice`, JSON.stringify(prijavnica));
}*/

export function GetPredmeti(): AxiosPromise<{ predmeti: IPredmet[] }> {
    if(cachedGetPredmetiPromise) return cachedGetPredmetiPromise;
    cachedGetPredmetiPromise = axios.get(`${baseAddress}/kurikulum/predmeti`, { headers: { authorization: 'Bearer ' + store.state.auth.token } });    
    return cachedGetPredmetiPromise;
}

export function GetPredmet(id: number): AxiosPromise<any> {
    if(getPredmetInProgress) return getPredmetInProgress;
    getPredmetInProgress = axios.get(`${baseAddress}/kurikulum/predmet/${id}`, { headers: { authorization: 'Bearer ' + store.state.auth.token } });
    getPredmetInProgress.then(()=>getPredmetInProgress = null);
    return getPredmetInProgress;
}

export function PostPredmet(predmet: IPredmet): AxiosPromise<boolean> {
    return axios.post(`${baseAddress}/kurikulum/store?predmet=${predmet.title}&program=${predmet.program}`, JSON.stringify(predmet), { headers: { authorization: 'Bearer ' + store.state.auth.token } });
}

export function DeletePredmet(id: number): AxiosPromise<boolean> {
    return axios.post(`${baseAddress}/kurikulum/delete/${id}`, "", { headers: { authorization: 'Bearer ' + store.state.auth.token } });
}

export function RenamePredmet(id: number, title: string): AxiosPromise<boolean> {
    return axios.post(`${baseAddress}/kurikulum/rename/${id}`, `"${title}"`, { headers: { authorization: 'Bearer ' + store.state.auth.token } });
}

export function Search(term: string, params: { program?: string, predmet?: string, temaId?: number } | null): AxiosPromise<any> {
    return axios.get(`${baseAddress}/kurikulum/search/?term=${term}`, { headers: { authorization: 'Bearer ' + store.state.auth.token }, params });
}
