
interface IAuthState {
    token: string | null;
    user: any;
    expiration?: number;
}

export default {
    namespaced: true,
    state: {
        token: null,
    },
    getters: {

    },
    mutations: {
        setToken(state: IAuthState, token: string) {
            state.token = token;
        },
        setData(state: IAuthState, data: {user: any, token: string, expiration: number}) {
            state.user = data.user;
            state.token = data.token;
            state.expiration = data.expiration;
        },
    },
    actions: {

    },
};
