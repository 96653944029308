






































































import { GetPredmet } from '@/api';
import { Component, Emit, Vue, Watch, Prop } from 'vue-property-decorator';
import { ITema, IPredmet, ISklop, IPodsklop } from '../model';


@Component({})
export default class App extends Vue {

    collapse!: () => void;

    @Prop({default: false})
    predmetSelect!: boolean;

    loading = false;
    expanded = {};

    get teme() {
        if (this.$store.state.selectedPredmet)
            return this.$store.state.selectedPredmet.teme;
        return [];
    }
    get predmet() {
        return this.$store.state.selectedPredmet;
    }
    get tema() {
        return this.$store.state.selectedTema;
    }
    get programi() {
        const pr = [...new Set(this.$store.state.predmeti.map((p: IPredmet) => p.program))] as string[];
        pr.sort((a: string, b: string) => a < b ? -1 : (a > b ? 2 : 0));
        return pr;
    }

    get predmeti() {
        return this.predmetList(this.$store.state.selectedProgram);
    }


    @Watch("predmet")
    predmetChanged(newPredmet: IPredmet) {
        this.expanded = {};
    }

    @Watch("tema")
    temaChanged(newTema: ITema) {
        if (newTema == null)
            this.expanded = {};
    }
    @Emit("tema-click")
    selectTema(tema: ITema) {
        this.$store.commit("setSelectedTema", tema ? tema : null);
        this.$store.commit("setSelectedSklop", null);
        this.$store.commit("setSelectedPodsklop", null);
        window.scrollTo(0, 0);
        return { text: tema?.title, temaId: tema.id, sklopId: null, podsklopId: null, predmetId: this.predmet.id, predmet: this.predmet.title, tema: tema.title };
        //if (tema)
        //  Vue.set(this.expanded, tema.id, true);
    }
    @Emit("sklop-click")
    selectSklop(sklop: ISklop, tema: ITema) {
        this.$store.commit("setSelectedTema", tema ? tema : null);
        this.$store.commit("setSelectedSklop", sklop ? sklop : null);
        this.$store.commit("setSelectedPodsklop", null);
        window.scrollTo(0, 0);
        return { text: sklop?.title, temaId: tema.id, sklopId: sklop.id, podsklopId: null ,  predmetId: this.predmet.id, predmet: this.predmet.title, tema: tema.title, sklop: sklop.title };
    }
    @Emit("podsklop-click")
    selectPodsklop(podsklop: IPodsklop, sklop: ISklop, tema: ITema) {
        this.$store.commit("setSelectedPodsklop", podsklop ? podsklop : null);
        window.scrollTo(0, 0);
        return { text: podsklop?.title, temaId: tema.id, sklopId: sklop.id, podsklopId: podsklop.id,  predmetId: this.predmet.id, predmet: this.predmet.title, tema: tema.title, sklop: sklop.title, podsklop: podsklop.title };
    }
    addSklop() {
        this.$q.dialog({
            title: 'Dodaj nov sklop',
            message: 'Ime sklopa',
            prompt: {
                model: '',
                type: 'text' // optional
            },
            cancel: true,
            persistent: true
        }).onOk((data: string) => {

            this.$store.commit("addSklop", { id: data, naziv: data, temaId: this.$store.state.selectedTema.id });
            // console.log('>>>> OK, received', data)
        }).onCancel(() => {
            // console.log('>>>> Cancel')
        }).onDismiss(() => {
            // console.log('I am triggered on both OK and Cancel')
        });
    }
    expandTema(tema: ITema, value: boolean) {

        if (value) {

            this.$store.commit("setSelectedTema", tema);
        }
        Vue.set(this.expanded, tema.id, value);
    }

    predmetList(program: string) {
        if (program == null) return [];
        const predmetiPrograma = this.$store.state.predmeti.filter((p: IPredmet) => p.program.toLowerCase() == program.toLowerCase());
        predmetiPrograma.sort((a: IPredmet, b: IPredmet) => a.title < b.title ? -1 : (a.title > b.title ? 2 : 0));
        return predmetiPrograma;
    }
    async setPredmet(predmet: IPredmet) {
        if (!predmet.isLoaded) {
            this.loading = true;
            try {
                const returned = await GetPredmet(predmet.id);

                predmet = { ...JSON.parse(returned.data.data), id: returned.data.id, isLoaded: true, program: predmet.program };

                this.$store.commit("addPredmet", predmet);
            } finally {
                this.loading = false;
            }
        }

        this.$store.commit("setSelectedPredmet", predmet);
        this.$store.commit("setSelectedTema", null);
        this.$store.commit("setSelectedSklop", null);
        this.$store.commit("setSelectedPodsklop", null);
        //window.scrollTo(0, 0);
        return predmet;
    }
    setProgram(program: string) {
        this.$store.commit('setSelectedProgram', program);
        if (this.$store.state.selectedPredmet && this.$store.state.selectedPredmet.program != program)
            this.$store.commit("setSelectedPredmet", null);
    }
}

