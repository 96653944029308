import katex, { KatexOptions } from 'katex';
import renderMathInElement from 'katex/dist/contrib/auto-render.js';
import { extend } from 'quasar';

const katexDirective = (el: HTMLElement, binding: any) => {
    const argOptions = (binding.value && binding.value.options) || {
        delimiters: [
            { left: "$$", right: "$$", display: false },
            { left: "**(", right: "**)", display: false },
            { left: "\\[", right: "\\]", display: true }
        ]
    };
    //const allOptions = extend(true, , argOptions) as any;
    if (binding.value === false || binding.value === null) return;
    if (binding.arg && binding.arg === 'auto') {

        renderMathInElement(el, argOptions);
    } else {

        const expression = binding.value.expression || binding.value;
        const displayMode: any = {};

        if (binding.arg === 'display') {
            displayMode.displayMode = true;
        }
        const options = extend(true, argOptions, displayMode) as KatexOptions;

        katex.render(expression, el, options);
    }
};

export default katexDirective;
