





































































































import {
    Editor,
    EditorContent,
    BubbleMenu,
    FloatingMenu,
} from '@tiptap/vue-2';

import Dropcursor, { DropcursorOptions } from '@tiptap/extension-dropcursor';
import Gapcursor from '@tiptap/extension-gapcursor';
import Document from '@tiptap/extension-document';
import Paragraph, { ParagraphOptions } from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import History, { HistoryOptions } from '@tiptap/extension-history';
import { BoldOptions } from '@tiptap/extension-bold';
import { Bold } from '@/editor/bold';
import Italic, { ItalicOptions } from '@tiptap/extension-italic';
import Heading, { HeadingOptions } from '@tiptap/extension-heading';
import HardBreak, { HardBreakOptions } from '@tiptap/extension-hard-break';
import Strike, { StrikeOptions } from '@tiptap/extension-strike';
import BulletList, { BulletListOptions } from '@tiptap/extension-bullet-list';
import OrderedList, { OrderedListOptions } from '@tiptap/extension-ordered-list';
import ListItem, { ListItemOptions } from '@tiptap/extension-list-item';
import { UnderlineOptions } from '@tiptap/extension-underline';
import { Underline } from '@/editor/underline';
import { Highlight, HighlightOptions } from '@tiptap/extension-highlight';

import { Katex } from "@/editor/katex";

import { Schema, DOMParser as pmDOMParser, DOMSerializer } from 'prosemirror-model';

import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';

@Component({ components: { EditorContent, BubbleMenu, FloatingMenu } })
export default class TextEditor extends Vue {
    @Prop()
    value!: string;
    @Prop({ default: '' })
    defaultValue!: string;

    editor: any = null;
    lastValue: string | null = null;

    mounted() {
        this.lastValue = this.value;
        this.editor = new Editor({
            extensions: [
                Document,
                Paragraph,
                Text,
                BulletList,
                ListItem,
                OrderedList,
                Bold,
                Italic,
                Underline,
                History,
                Dropcursor,
                Gapcursor,
                Katex,
                Highlight.configure({ multicolor: true }),
                //new KatexInline(),
                //  new Image(),
                //   new Ms(),
                //  new Izb(),
                //  new Ind()
            ],
            content: this.value ? this.value : this.defaultValue,
            onUpdate: ({ editor }) => {
                const html = editor.getHTML();
                this.lastValue = html;
                this.$emit("input", html);
                //   (this as any).dump();
            },
            onFocus: (e) => this.$emit("focus", e),
            onBlur: (e) => this.$emit("blur", e),
        });
        //this.editor = editorInstance.editor;
    }

    beforeDestroy() {
        setTimeout(() => (this as any).editor.destroy(), 1000);
    }

    @Watch("value")
    valueChanged() {
        if (this.lastValue != this.value) {
            this.lastValue = this.value;
            this.editor.commands.setContent(this.value);
        }
    }

    dump() {
        try {
            const v = false;
            if (!v) return;
            const dp = new DOMParser();
            const ser = DOMSerializer.fromSchema((this as any).editor.schema);
            console.log((this as any).editor.getJSON());
            (this as any).editor.state.doc.content.content.map((el: any) => {
                console.log("new");
                console.log(el);
                console.log(el.toJSON());

                const serialized = ser.serializeNode(el);
                const serializedText = (serialized as any).innerHTML;
                console.log(serializedText);
                const serializedText1 = (serialized as any).outerHTML;
                console.log(serializedText1);
                const node = pmDOMParser.fromSchema((this as any).editor.schema.nodes.paragraph.schema).parse(dp.parseFromString(serializedText, "text/html")).toJSON().content;

                console.log(node);
            });
            console.log((this as any).editor.schema);

        } catch {
            //aa
        }
        //return div.innerHTML
    }

    log(o: any) {
        console.log(o);
    }

    get isEditorReady() {
        return (this as any)?.editor != null;
    }
}
