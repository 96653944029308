









































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IVsebina, ISklop, ISekcija, ILine } from '../model';
import Podsklop from "./podsklop.vue";
import Katex from '@/directives/katex';

type IVsebinaWithVio = IVsebina & { vio: number[] };
@Component({ components: { Podsklop }, directives: { katex: Katex } })
export default class Vsebina extends Vue {
    @Prop()
    vsebina !: IVsebina;

    get passesRazredFilter() {
        const razredi = this.vsebina.razredi as number[];
        if (razredi == null || razredi.length == 0) return true;
        return razredi.some(e => e >= this.minRazred && e <= this.maxRazred);
    }

    get minRazred() {
        return this.$store.state.filters.razred.min;
    }
    get maxRazred() {
        return this.$store.state.filters.razred.max;
    }
    isSelected(id: number) {
        return this.$store.state.selectedSearchResult && this.$store.state.selectedSearchResult.id == id;
    }

    onLineDoubleClick(line: ILine) {
        if (this.$store.state.priprava.pickerFunction) {
            this.$store.state.priprava.pickerFunction(line);
        }
        if (this.$store.state.allowEditing) {
            Vue.set(line, "priority", !line.priority);
            this.$store.commit("setPendingChanges", true);
        }
    }

    convertTypeToModelType(type: number) {
        if (type === 9) return { type: "image" };
        if (type === 0) return { type: "paragraph" };
        if (type >= 1 && type <= 4) return { type: "heading", attrs: { level: type } };
        if (type == 5) return { type: "list" };
        if (type >= 6 && type <= 8) return { type: "list", attrs: { level: type - 5 } };
    }

    getModel() {
        this.vsebina.text.map(l => {
            const data = this.convertTypeToModelType(l.type);
            return { ...data, content: this.splitText(l.text) };
        });
    }

    splitText(text: string) {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(text, 'text/html');

    }
}
